<template>
    <b-container class="pt-3">
        <h2>PWD Code</h2>
        <hr class="mt-1 mb-3" />

        <b-form @submit.prevent="resetPassword" class="mb-4">
            <b-form-group
            id="emailGroup"
            label="Email address"
            label-for="emailInput"
            >
            <b-form-input
                id="emailInput"
                type="email"
                autocorrect="off"
                autocapitalize="none"
                v-model.trim="form.email"
                required
                placeholder="email@domain"
            ></b-form-input>
            </b-form-group>

            <b-form-group
            id="tokenGroup"
            label="Token"
            label-for="tokenInput"
            >
            <b-form-input
                id="tokenInput"
                autocorrect="off"
                v-model.trim="form.reset_token"
                type="text"
                required
            ></b-form-input>
            </b-form-group>

            <b-form-group
          id="newPasswordGroup"
          label="New password"
          label-for="newPasswordInput"
          invalid-feedback="Password doesn't meet minimum requirements"
        >
          <b-form-input
            id="newPasswordInput"
            v-model="form.new_password_1"
            :state="newPasswordState"
            type="password"
            required
            placeholder="Enter new password"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="newPasswordGroup2"
          label="Confirm New Password"
          label-for="repeatNewPasswordInput"
          invalid-feedback="Passwords don't match"
        >
          <b-form-input
            id="repeatNewPasswordInput"
            v-model="form.new_password_2"
            :state="confirmPasswordState"
            type="password"
            required
            placeholder="Enter new password"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary" block
            >Submit<b-spinner small v-if="submitting"
        /></b-button>
      </b-form>
    </b-container>
</template>

<script>
export default {
    name: "reset token",
    data () {
        return {
            submitting: false,
            form: {
                reset_token: "",
                email: "",
                new_password_1: "",
                new_password_2: ""
            }
        };
    },
    methods: {
        async resetPassword () {
            console.log ("resetting password");
        
            this.submitting = true;

            try {
                let response = await this.$http.post(
                this.$store.state.apiURL + "/account/reset-password",
                this.form
                );
                
                if (response.status == 200) {
                    console.log ("reset pwd code succeeded");

                    this.$router.push({path: "/login"});
                }
            } catch (error) {
                this.handleError(error);

                console.log ("error with reset pwd code");
            }

            this.submitting = false;
        }
    },
    computed: {
    newPasswordState() {
      if (this.form.new_password_1.length > 0) {
        let regex = new RegExp("((?=.*\\d)(?=.*[A-Z])(?=.*\\W).{8,})");
        return regex.test(this.form.new_password_1);
      } else {
        return null;
      }
    },
    confirmPasswordState() {
      if (this.form.new_password_2 != this.form.new_password_1) {
        return false;
      } else {
        return null;
      }
    },
  },
}
</script>

<style lang="scss">
@import "../../scss/theme.scss";

h4 {
  color: #003e69;
}
</style>
